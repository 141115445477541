<template>
  <div>
    <DataTable
      :value="list"
      :totalRecords="totalRecords"
      :loading="loading"
      :sortField="lazyParams.sortField"
      :sortOrder="lazyParams.sortOrder"
      :rowsPerPageOptions="[10, 20, 50]"
      :rows="20"
      lazy
      rowHover
      paginator
      stripedRows
      showGridlines
      class="p-datatable-sm"
      filterDisplay="menu"
      responsiveLayout="scroll"
      v-model:filters="lazyParams.filters"
      @row-click="edit($event.data)"
      @page="onLazyParams($event)"
      @sort="onLazyParams($event)"
      @filter="onLazyParams($event)"
      >
      <template #header>
        <div class="flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText :placeholder="$t('buttons.search')" v-model="lazyParams.filters['search'].value" @keydown.enter="getList()" />
          </span>
          <Button type="button" icon="pi pi-plus" :label="$t('buttons.newProject')" class="p-button-outlined" @click="create()" />
        </div>
      </template>

      <template #empty>
        {{$t('projects.error.noProjectFound')}}.
      </template>

      <template #loading>
        {{$t('projects.loadingProjects')}}. {{$t('common.pleaseWait')}}.
      </template>

      <Column field="name" :header="$t('common.name')" sortable />

      <Column field="logo">
        <template #header>
          <div class="flex justify-content-end w-full">
            <div class="w-full text-center">Logo</div>
          </div>
        </template>

        <template #body="slotProps">
          <div v-if="slotProps.data.pxfileUrl" class="text-center h-2rem">
            <img :src="slotProps.data.pxfileUrl" class="h-full" />
          </div>
          <div class="flex justify-content-end w-full">
          </div>
        </template>
      </Column>

      <Column>
        <template #header>
          <div class="flex justify-content-end w-full">
            <div>{{ $t('common.actions') }}</div>
          </div>
        </template>

        <template #body="slotProps">
          <div class="flex justify-content-end w-full">
            <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-outlined mr-2" @click="edit(slotProps.data)" />
            <Button
              icon="pi pi-trash"
              class="p-button-rounded p-button-danger p-button-outlined"
              :loading="slotProps.data.__isRemoving"
              @click="confirmRemove(slotProps.data)"
            />
          </div>
        </template>
      </Column>

    </DataTable>

    <ProjectNewDialog v-model="showCreateProject" />
  </div>
</template>

<script>
import { onMounted, ref, inject } from 'vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import ProjectNewDialog from './components/ProjectNew.vue'
import { useTabMemory } from '@/components/base/TabEditor.vue'

export default {
  components: {
    DataTable, Column, InputText, ProjectNewDialog
  },
  setup () {
    const { reset: resetTabMemory } = useTabMemory()
    const {t} = useI18n()
    const KFlow = "project"
    const breadcrumb = inject('breadcrumb')
    const pxstream = inject('pxstream')
    const router = useRouter()
    const toast = useToast()
    const confirm = useConfirm()
    const showCreateProject = ref(false)
    const loading = ref(false)
    const totalRecords = ref(0)
    const list = ref(null)
    let lazyParams = ref({
      sortField: 'name',
      sortOrder: 1,
      filters: {
        'search': {value: '', matchMode: 'contains'},
        'licensor.name': {value: '', matchMode: 'contains'},
        'contentType.name': {value: '', matchMode: 'contains'},
      },
    })

    const create = () => {
      showCreateProject.value = true
    }

    const getList = async () => {
      const params = JSON.parse(JSON.stringify(lazyParams.value))
      loading.value = true
      try {
        const {data, total} = await pxstream.project.getProjectList(params)
        totalRecords.value = total
        list.value = (data || [])
        list.value.map(val => {
          if (val.logo?.uuid) {
            val.pxfileUrl = pxstream.storage.getPreviewFileUrl(val.logo.uuid, 400, 40)
          }
        })
      } catch (err) {
        toast.add({severity: 'error', summary: 'Failed to get projects', detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
      }
      loading.value = false
    }

    const edit = (e) => {
      resetTabMemory()
      router.push(`/projects/${e.id}`)
    }

    const remove = (data) => {
      data.__isRemoving = true
      pxstream.deleteOne(KFlow, data.id)
        .then(() => {
          toast.add({ severity: 'success', detail: 'Removed with success', summary: data.name, life: 4000 })
          data.__isRemoving = false
          getList()
        })
        .catch((err) => {
          data.__isRemoving = false
          toast.add({severity: 'error', summary: 'Failed to remove project '+ data.name, detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        })
    }

    const confirmRemove = (data) => {
      confirm.require({
        message: "Are you sure you want to delete this project?",
        header: `Delete ${data.name}`,
        acceptLabel: t('actions.yes'),
        rejectLabel: t('actions.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          remove(data)
        },
        reject: () => {}
      })
    }

    const onLazyParams = (e) => {
      if (e) {
        lazyParams.value = e
      }
      getList()
    }


    onMounted(() => {
      breadcrumb.setCurrent('Projects', [
        {label: 'Projects', disabled: true}
      ])
      getList()
    })

    return {
      loading,
      list,
      showCreateProject,
      totalRecords,
      lazyParams,
      getList,
      create,
      edit,
      confirmRemove,
      remove,
      onLazyParams,
    }
  }
}
</script>
