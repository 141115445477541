<template>
  <div>
    <div v-if="doc">
      <ProjectEditingCore :doc="doc" :flow="flow" @has-update="onUpdateChanged">
        <template #header="{ hasUpdate, hasError, hasLinksError, saveFlowBuild }">
          <div class="flex justify-content-end mb-2">
            <div class="mr-3">
              <Button :label="$t('actions.back')"
                class="p-button-outlined"
                :disabled="isSaving"
                :loading="isSaving"
                @click="backToList"
              />
            </div>
            <div class="mr-3">
              <Button :label="exportEvent.status === 'in-progress' ? $t('actions.exporting') : $t('actions.export')"
                class="p-button-outlined"
                :disabled="hasUpdate || isSaving || hasLinksError('*') || hasError"
                :loading="isSaving"
                @click="openExport"
              />
            </div>
            <div class="mr-3">
              <Button :label="$t('actions.save')"
                icon="pi pi-check"
                iconPos="right"
                :disabled="!hasUpdate || isSaving || hasLinksError('*') || hasError || exportEvent.status === 'in-progress'"
                :loading="isSaving"
                @click="save(saveFlowBuild('*'))"
              />
            </div>
          </div>
        </template>
      </ProjectEditingCore>
      <ExportDialog v-model="showExportDialog" :doc="doc" />
    </div>
    <div v-else>
      <div class="flex justify-content-center">
        <p class="text-4xl font-light text-center">
          {{ isReloading ? $t('projects.one.reload') : $t('projects.one.loading') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, provide, onMounted, onUnmounted, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { useFlowCruder, useFlowReset } from '@/compositions'
import ProjectEditingCore from './components/ProjectEditingCore.vue'
import { onBeforeRouteLeave } from 'vue-router'
import ExportDialog from './components/ExportDialog.vue'
import { LOGIN_PAGE } from '@/router'
import { useExporterProjectEvent } from '@/compositions'
import { useI18n } from 'vue-i18n'


export default {
  name: 'ProjectEditing',
  components: {
    ProjectEditingCore, ExportDialog
  },
  setup () {
    const KFlow = "project"

    // Variables
    const isReloading = ref(false)
    const doc = ref(null)
    const hasUpdate = ref(false)
    const lastUpdate = ref(new Date(0))
    const { flowGet, flowSave, ...flowCruderAPI } = useFlowCruder()
    const isNewItemCreating = ref(false)
    const showExportDialog = ref(false)

    const route = useRoute()
    const router = useRouter()
    const confirm = useConfirm()
    const toast = useToast()
    const breadcrumb = inject('breadcrumb')
    const {t} = useI18n()
    const pxstream = inject('pxstream')

    const projectId = route.params.id

    // const {state: eventState, event: eventMsg} = useEventProjectExport()

    const { lastEvent: exportEvent, close: closeService } = useExporterProjectEvent(projectId)

    // Methods
    const onUpdateChanged = (payload) => {
      hasUpdate.value = payload.hasUpdate
      lastUpdate.value = payload.at
    }

    const reloadDoc = async () => {
      isReloading.value = true
      useFlowReset()
      // Force EditingCore setup by removing dom
      doc.value = null
      const { data } = await flowGet(KFlow, projectId)
      doc.value = data
      isReloading.value = false
      // Reset hasUpdate flag
      onUpdateChanged({
        hasUpdate: false,
        at: new Date()
      })
    }

    const onAskReload = () => { reloadDoc() }

    const save = async (req) => {
      try {
        await flowSave(req)
        toast.add({severity: 'success', detail: t('projects.one.save.success'), summary: req.doc.name, life: 4000})
        reloadDoc()
      } catch (e) {
        toast.add({severity: 'error', summary: t('projects.one.save.error'), detail: e.error || e.message, life: 4000})
      }
    }

    const backToList = () => {
      router.push('/projects')
    }

    const openExport = () => {
      showExportDialog.value = true
    }

    // Computed

    // Watchers

    // Provides
    provide('askReload', reloadDoc)

    // Hooks
    onBeforeRouteLeave((to, from, next) => {
      if (!hasUpdate.value) {
        return next()
      } else if (to.name === LOGIN_PAGE && to.query.redirect) {
        return next()
      }
      confirm.require({
        header: t('guards.leaveEditor.header'),
        message: t('guards.leaveEditor.message'),
        acceptLabel: t('actions.yes'),
        rejectLabel: t('actions.no'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => { next() },
        reject: () => { next(false) }
      })
    })

    onMounted(async () => {
      useFlowReset()

      const docId = route.params.id
      let title = `${t('projects.label')} ${docId}`
      let owner = '-- No Owner--'

      try {
        const { data } = await flowGet(KFlow, docId)
        doc.value = data
        title = doc.value.name
        owner = doc.value.owner?.username || owner
        if (owner) owner = `By ${owner}`
      } catch (err) {
        toast.add({severity: 'error', summary: t('projects.one.get.error'), detail: pxstream.http.getHttpErrorShortMessage(err), life: 4000})
        setTimeout(() => router.push('/projects', 4000))
      }

      breadcrumb.setCurrent(title, [
        {label: 'Projects', disabled: false, to: '/projects'},
        {label: title, disabled: true}
      ], {caption: owner, badge: docId === 'new' ? t('common.draft') : ''})

    })

    onUnmounted(() => {
      closeService()
    })

    return {
      projectId,
      exportEvent,
      isReloading,
      isNewItemCreating,
      hasUpdate,
      lastUpdate,
      flow: KFlow,
      doc,
      ...flowCruderAPI,
      onUpdateChanged,
      onAskReload,
      reloadDoc,
      save,
      backToList,
      openExport,
      showExportDialog,

      // eventState,
      // eventMsg
    }
  }
}
</script>
