<template>
  <MultiSelectBase :filter="!allLabel && $props.filter" v-bind="$props" :modelValue="modelValue" @update:modelValue="$emit('update:modelValue', $event)">
    <template #header v-if="allLabel">
      <span class="p-multiselect__allLabel">{{allLabel}}</span>
    </template>
    <template #value v-if="$props.modelValue?.length === $props.options?.length">
      {{$t('common.all')}}
    </template>
  </MultiSelectBase>
</template>

<script>
import MultiSelectBase from 'primevue/multiselect'
export default {
  components: { MultiSelectBase },
  props: {
    ...MultiSelectBase.props,
    allLabel: String,
  }
}
</script>

<style lang="postcss" scoped>
span.p-multiselect__allLabel {
  position: absolute;
  padding: 1.1rem 0rem;
  margin-left: 3.5rem;
}
</style>