<template>
  <Dialog
    v-model:visible="showIt"
    class="w-11 md:w-6"
    @hide="resetFields({ withType: true })"
    @show="initFields"
    modal
    dismissableMask
    closeOnEscape>
    <div class="flex flex-column ruleEditorModal">
      <div class="flex col-12 justify-content-center align-items-center">
        <label for="types" class="col-4 text-right">{{$t('projects.rules.field.type', 2)}}: </label>
        <div class="col-8">
          <DropDown id="types" :options="ruleTypes" v-model="typeSelected" optionLabel="name" optionValue="value" />
        </div>
      </div>
      <div v-if="showValue" class="flex col-12 justify-content-center align-items-center">
        <label for="value" class="col-4 text-right">{{$t('projects.rules.field.value')}}: </label>
        <div class="col-8">
          <SelectButton id="value" :options="valueList" v-model="valueSelected"/>
        </div>
      </div>
      <div v-if="showSection" class="flex col-12 justify-content-center align-items-center">
        <label for="section" class="col-4 text-right">{{$t('projects.rules.field.section', 2)}}: </label>
        <div class="col-8">
          <MultiSelect id="section" class="w-full max-w-full" :allLabel="$t('common.selectAll')" :options="sectionList" :loading="loadingSections" v-model="sectionSelected" optionLabel="name" optionValue="id" :placeholder="$t('common.selectSections')"></MultiSelect>
        </div>
      </div>
      <div v-if="showCompatibleSystems" class="flex col-12 justify-content-center align-items-center">
        <label for="compatibleSystems" class="col-4 text-right">{{$t('projects.rules.field.compatibleSystems')}}: </label>
        <div class="col-8">
          <MultiSelect id="compatibleSystems" class="w-full max-w-full" :allLabel="$t('common.selectAll')" :options="systems" v-model="systemsSelected" optionLabel="name" optionValue="id"></MultiSelect>
        </div>
      </div>
      <div v-if="showDates" class="flex col-12 justify-content-center align-items-center">
        <label for="startDate" class="col-4 text-right">{{$t('projects.rules.field.startDate')}}: </label>
        <div class="col-8">
          <div class="flex flex-column ruleFormInput">
            <Calendar id="startDate" dateFormat="yy-mm-dd" v-model="startDate"
              :class="{'p-invalid': startDateError}" />
            <span class="ruleFormInput__error p-error" v-if="startDateError">{{startDateError}}</span>
          </div>
        </div>
      </div>
      <div v-if="showDates" class="flex col-12 justify-content-center align-items-center">
        <label for="endDate" class="col-4 text-right">{{$t('projects.rules.field.endDate')}}: </label>
        <div class="col-8">
          <div class="flex flex-column ruleFormInput">
            <Calendar id="endDate" dateFormat="yy-mm-dd" v-model="endDate"
              :class="{'p-invalid': endDateError}" />
            <span class="ruleFormInput__error p-error" v-if="endDateError">{{endDateError}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-content-between mt-2">
      <Button :label="$t('actions.cancel')" icon="pi pi-times" class="p-button-text mr-4" @click="cancelRule()"/>
      <Button :label="$t(`actions.${rule ? 'update': 'create'}`)" :disabled="v$.$invalid" icon="pi pi-check" @click="createOrUpdateRule()"/>
    </div>
  </Dialog>
</template>


<script>
import Dialog from 'primevue/dialog'
import MultiSelect from '@/components/base/MultiSelect.vue'
import DropDown from 'primevue/dropdown'
import SelectButton from 'primevue/selectbutton'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'

import { ref, computed, watch, inject } from 'vue'

import { required, minValue } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"

import { useI18n } from 'vue-i18n'

import ruleTypes, {
  K_PLAY_PERIOD_RULE_TYPE,
  K_PRIORITY_RULE_TYPE,
  K_SYSTEMS_RULE_TYPE,
  K_THUMBNAIL_EMBEDDED_RULE_TYPE,
  K_TRAILER_EMBEDDED_RULE_TYPE
} from '@/pages/projects/core/ruleTypes'
import { getSectionsOfType } from '@/pages/projects/core/sectionUtils'

export default {
  name: 'RuleEditorModal',
  components: {
    Dialog, MultiSelect, DropDown, Button, SelectButton, Calendar
  },
  props: {
    visible: Boolean,
    loadingSections: Boolean,
    cspMenuId: [Number, String],
    rule: [null, Object],
    sections: Object,
    systems: Array
  },
  emits: ['update:visible', 'addRule', 'updateRule'],
  setup (props, { emit }) {

    const showIt = computed({
      get () { return props.visible },
      set (value) {
        emit('update:visible', value)
      }
    })


    const pxstream = inject('pxstream')
    const {t} = useI18n()

    const typeList = ref([])
    const valueList = ref([])
    const sectionSelected = ref(null)
    const systemsSelected = ref(null)
    const valueSelected = ref(null)
    const typeSelected = ref(null)
    const startDate = ref(null)
    const endDate = ref(null)

    const showValue = ref(false)
    const showSection = ref(false)
    const showCompatibleSystems = ref(false)
    const showDates = ref(false)

    const sectionList = computed(() => {
      switch (typeSelected.value) {
        case K_TRAILER_EMBEDDED_RULE_TYPE :
          return getSectionsOfType('Movie', props.sections)
        case K_THUMBNAIL_EMBEDDED_RULE_TYPE:
          return getSectionsOfType(['Movie', 'TV', 'Audio'], props.sections)
        case K_SYSTEMS_RULE_TYPE :
          return getSectionsOfType(['Movie', 'TV', 'Audio'], props.sections)
        case K_PRIORITY_RULE_TYPE :
          return getSectionsOfType(['Movie', 'TV'], props.sections)
        case K_PLAY_PERIOD_RULE_TYPE :
          return getSectionsOfType(['Movie', 'TV', 'Audio', 'Broadcast'], props.sections)
      }
      return []
    })

    const requiredIfTypeSelected = (...types) => {
      return (value) => {
        if (types.includes(typeSelected.value)) {
          return (value != null && value !== "")
        }
        return true
      }
    }

    const rules = {
      typeSelected: {
        required
      },
      sectionSelected: {
        required
      },
      systemsSelected: {
        required: requiredIfTypeSelected(K_SYSTEMS_RULE_TYPE)
      },
      startDate: {
        required: requiredIfTypeSelected(K_PLAY_PERIOD_RULE_TYPE)
      },
      endDate: {
        minValue: minValue(startDate)
      },
      valueSelected: {
        required: requiredIfTypeSelected(K_PRIORITY_RULE_TYPE, K_THUMBNAIL_EMBEDDED_RULE_TYPE, K_TRAILER_EMBEDDED_RULE_TYPE)
      }
    }

    const v$ = useVuelidate(rules, {
      typeSelected,
      sectionSelected,
      systemsSelected,
      valueSelected,
      startDate,
      endDate,
    })

    const sectionError = computed(() => (v$.value.$dirty && v$.value.sectionSelected.$invalid ? 'Section is required' : ''))
    const systemsError = computed(() => (v$.value.systemsSelected.$dirty && v$.value.systemsSelected.$invalid ? 'System is required' : ''))
    const valueError = computed(() => (v$.value.valueSelected.$dirty && v$.value.valueSelected.$invalid ? 'Value is required' : ''))
    const typeError = computed(() => (v$.value.typeSelected.$dirty && v$.value.typeSelected.$invalid ? 'Type is required' : ''))
    const startDateError = computed(() => (v$.value.startDate.$dirty && v$.value.startDate.$invalid ? 'Start date is required' : ''))
    const endDateError = computed(() => (v$.value.endDate.$dirty && v$.value.endDate.$invalid ? 'End date must be greater than start date' : ''))


    const trueValues = [t('actions.yes'), t('actions.no')]

    const initFields = () => {
      if (props.rule) {
        typeSelected.value = props.rule.type
        switch (props.rule.type) {
          case K_TRAILER_EMBEDDED_RULE_TYPE:
          case K_THUMBNAIL_EMBEDDED_RULE_TYPE:
            valueSelected.value = props.rule.value ? 'on' : 'off'
            break;
          case K_PRIORITY_RULE_TYPE:
            valueSelected.value = props.rule.value ? t('actions.yes') : t('actions.no')
            break;
          case K_PLAY_PERIOD_RULE_TYPE:
            startDate.value = pxstream.tools.fromSimpleDate(props.rule.value.periodFrom)
            endDate.value = pxstream.tools.fromSimpleDate(props.rule.value.periodTo)
            break;
          case K_SYSTEMS_RULE_TYPE:
            systemsSelected.value = props.rule.value.map(({id}) => id)
            break;
        }
        // Sections
        sectionSelected.value = props.rule.all ?
          sectionList.value.map(({id}) => id)
          : props.rule.sections.map(({id}) => id)
      }
    }

    const resetFields = ({withType, onlyShows}) => {
      if (withType) {
        typeSelected.value = null
      }
      showValue.value = false
      showSection.value = false
      showCompatibleSystems.value = false
      showValue.value = false
      showDates.value = false
      if (!onlyShows) {
        valueSelected.value = null
        systemsSelected.value = null
        sectionSelected.value = null
        startDate.value = null
        endDate.value = null
      }
      v$.value.$reset()
    }

    const searchTypes = ({query}) => {
      typeList.value = ruleTypes.filter((t) => t.name.toLowerCase().includes(query.toLowerCase()))
      return true
    }

    const getValue = (type) => {
      switch(type) {
        case K_TRAILER_EMBEDDED_RULE_TYPE:
        case K_THUMBNAIL_EMBEDDED_RULE_TYPE:
        case K_PRIORITY_RULE_TYPE:
          return trueValues.includes(valueSelected.value)
        case K_PLAY_PERIOD_RULE_TYPE:
          return {
            periodFrom: pxstream.tools.toSimpleDate(startDate.value),
            periodTo: pxstream.tools.toSimpleDate(endDate.value) || null
          }
        case K_SYSTEMS_RULE_TYPE:
          return systemsSelected.value.map((id) => ({id}))
      }
      return false
    }

    const createOrUpdateRule = () => {
      v$.value.$touch()
      if (!v$.value.$invalid) {
        const type = typeSelected.value
        let rule = {
          type,
          value: getValue(type),
          sections: sectionSelected.value.map((id) => ({id})),
          all: sectionSelected.value.length === sectionList.value.length
        }
        emit(props.rule ? 'updateRule' : 'addRule', rule)
        showIt.value = false
      }
    }

    const cancelRule = () => {
      showIt.value = false
    }

    watch(typeSelected, () => {
      resetFields({ onlyShows: true })
      switch (typeSelected.value) {
        case K_TRAILER_EMBEDDED_RULE_TYPE :
          valueList.value = ['on', 'off']
          showValue.value = true
          showSection.value = true
          break
        case K_THUMBNAIL_EMBEDDED_RULE_TYPE:
          valueList.value = ['on', 'off']
          showValue.value = true
          showSection.value = true
          break
        case K_SYSTEMS_RULE_TYPE :
          showCompatibleSystems.value = true
          showSection.value = true
          break
        case K_PRIORITY_RULE_TYPE :
          valueList.value = [t('actions.yes'), t('actions.no')]
          showValue.value = true
          showSection.value = true
          break
        case K_PLAY_PERIOD_RULE_TYPE :
          showDates.value = true
          showSection.value = true
          break
        default:
      }
      v$.value.$validate()
    }, { immediate: true })

    return {
      showIt,
      ruleTypes,
      valueList,
      sectionList,
      sectionSelected,
      sectionError,
      systemsSelected,
      systemsError,
      valueSelected,
      valueError,
      typeSelected,
      typeError,
      endDate,
      endDateError,
      startDate,
      startDateError,
      showDates,
      showValue,
      showSection,
      showCompatibleSystems,
      searchTypes,
      createOrUpdateRule,
      cancelRule,
      resetFields,
      initFields,
      v$
    }
  },
}
</script>

<style lang="scss" scoped>
.ruleEditorModal {
  min-height: 10rem;
}
.ruleFormInput {
  position: relative;
  &__error {
    position: absolute;
    bottom: -1.4rem;
  }
}
</style>
