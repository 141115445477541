<template>
  <div>
    <div class="grid" v-if="ifeSystems.length">
      <div class="col-2 border-right-1 flex flex-column">
        <Button
          v-for="(ifeSystem, index) in ifeSystems"
          :key="ifeSystem.ife.id"
          @click="activeIndex = index"
          :label="ifeSystem.ife.name"
          :class="[{'p-button-danger': isNewGenre(ifeSystem.ife.iso), 'font-bold' : isActive(index)}, 'p-button-text text-900 align-self-start']"
        >
          <span :class="{'p-error': isNewGenre(ifeSystem.ife.iso)}">
            <i v-if="isNewGenre(ifeSystem.ife.iso)" class="mr-1 pi pi-exclamation-triangle"></i> {{ifeSystem.ife.name}}
          </span>
        </Button>
      </div>
      <div class="col-10" id="project-hide-nav-tab">
        <TabView v-model:activeIndex="activeIndex">
          <TabPanel v-for="ifeSystem in ifeSystems" :key="ifeSystem.ife.id">

            <div class="field grid">
              <label for="version" :class="{'p-error': cspOutputError, 'col-12 md:col-2 font-bold': true}">Output</label>
              <div class="col md:col-6">
                <span class="p-fluid">
                  <AutoCompleteSearch
                    class="col-10 w-max"
                    :class="{'p-invalid': cspOutputError}"
                    id="output"
                    :suggestions="outputSuggestions"
                    itemValue="value"
                    field="name"
                    v-model="cspOutput"
                    @complete="searchOutputItems()"
                    dropdown
                    dropdownIcon="search"
                  />
                  <small v-if="cspOutputError" class="p-error px-2">{{cspOutputError}}</small>
                </span>
              </div>
            </div>

            <div class="field grid">
              <label for="version" :class="{'p-error': cspMenuError, 'col-12 md:col-2 font-bold': true}">Menu</label>
              <div class="col md:col-6">
                <span class="p-fluid">
                  <AutoCompleteSearch
                    class="col-10 w-max"
                    :class="{'p-invalid': cspMenuError}"
                    id="menu"
                    :suggestions="menuList"
                    v-model="cspMenu"
                    @complete="searchMenus($event)"
                    field="name"
                    itemValue="id"
                    dropdown
                    dropdownIcon="search"
                  />
                  <small v-if="cspMenuError" class="p-error px-2">{{cspMenuError}}</small>
                </span>
              </div>
            </div>
            <Accordion :activeIndex="0">
              <AccordionTab :header="$t('projects.export.rules')">
                <RulesTab :editorKey="editorKey" :ifeSystemId="ifeSystem.ife.id"/>
              </AccordionTab>
              <AccordionTab :header="$t('projects.export.genres')">
                <OverloadedGenresTab :editorKey="editorKey" :ifeSystemId="ifeSystem.ife.id" />
              </AccordionTab>
            </Accordion>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div class="text-center" v-else>{{$t('projects.export.noIfeProduct')}}</div>
  </div>
</template>

<script>
import AutoCompleteSearch from '@/components/base/AutoCompleteSearch.vue'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Button from 'primevue/button'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import { useConfirm } from 'primevue/useconfirm'

import RulesTab from './components/RulesTab.vue'
import OverloadedGenresTab from './components/OverloadedGenresTab.vue'
import Log from '@/services/logger'

import { computed, ref, inject } from 'vue'
import { useFlowEditor } from '@/compositions'
import { useI18n } from 'vue-i18n'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default {
  name: 'ProjectExport',
  components: {
    RulesTab, OverloadedGenresTab, AutoCompleteSearch, Accordion, AccordionTab, Button, TabPanel, TabView
  },
  props: { editorKey: String, isExportCspVisible: Boolean },
  setup (props) {
    const {fieldGet, fieldSet, setError} = useFlowEditor(props.editorKey)

    const pxstream = inject('pxstream')
    const confirm = useConfirm()
    const {t} = useI18n()

    const outputSuggestions = ref([{id: 0, value: 'excel', name: 'Excel file'}])
    const menuList = ref([])
    const activeIndex = ref(0)

    const projectEditorKey = computed(() => props.editorKey)
    const ifeSystems = computed(() => (
        Object.keys(fieldGet('ifeSystems'))
          .map((id) => fieldGet(`ifeSystems.${id}`) )
    ))

    const currentIfe = computed(() =>
      (ifeSystems.value[activeIndex.value].ife.id)
    )

    const cspOutput = computed({
      get: () => (outputSuggestions.value.find((output) => fieldGet(`ifeSystems.${currentIfe.value}.cspOutput`) === output.value)),
      set: (value) => (fieldSet({field: `ifeSystems.${currentIfe.value}.cspOutput`, value: value.value}))
    })

    const cspOutputError = computed(() => {
      if (v$.value.cspOutput.required.$invalid ) return 'Output is required'
      return ''
    })

    const cspMenuError = computed(() => {
      if (v$.value.cspMenu.required.$invalid ) return 'Menu is required'
      return ''
    })

    const cspMenu = computed({
      get: () => (fieldGet(`ifeSystems.${currentIfe.value}.cspMenu`)),
      set: (value) => {
        if (value.id) {
          let cspMenu = value.id ? { id: value.id, name: value.name} : value
          const currentCspMenu = fieldGet(`ifeSystems.${currentIfe.value}.cspMenu`)
          const rules = fieldGet(`ifeSystems.${currentIfe.value}.rules`)
          const notAllSections = (rule) => !rule.all
          const withAllSections = (rule) => rule.all
          if (currentCspMenu && currentCspMenu.id !== value.id && (rules || []).filter(notAllSections).length > 0) {
            confirm.require({
              header: t('projects.export.changeMenuWarning.header'),
              message: t('projects.export.changeMenuWarning.message'),
              acceptLabel: t('actions.yes'),
              rejectLabel: t('actions.no'),
              icon: 'pi pi-exclamation-triangle',
              accept: () => {
                fieldSet({field: `ifeSystems.${currentIfe.value}.cspMenu`, value: cspMenu})
                fieldSet({field: `ifeSystems.${currentIfe.value}.rules`, value: rules.filter(withAllSections)})
              },
              reject: () => { }
            })
          } else {
            fieldSet({field: `ifeSystems.${currentIfe.value}.cspMenu`, value: cspMenu})
          }
        } else {
          fieldSet({field: `ifeSystems.${currentIfe.value}.cspMenu`, value})
        }
      }
    })

    const rules = {
      cspOutput: {
        required,
      },
      cspMenu: {
        required,
      }
    }

    const v$ = useVuelidate(rules, { cspOutput, cspMenu }, { $scope: false })

    if (v$.value.$invalid) {
      setError(new Error('InvalidForm'), 'ProjectExport')
    }

    const isNewGenre = inject('isNewGenre')

    const searchOutputItems = () => {
      outputSuggestions.value = [{id: 0, value: 'excel', name: 'Excel file'}]
      return true
    }


    const searchMenus = async ({query}) => {
      try {
        const qryBuilder = pxstream.tools.createQueryBuilder()
        qryBuilder.setFilterSearch(query)
        const { data: list } = await pxstream.portalmanager.getMenuList(qryBuilder.build())
        menuList.value = list
      } catch (e) {
        Log.Error(e)
      }
    }

    const isActive = (index) => activeIndex.value === index

    return {
      v$,
      activeIndex,
      projectEditorKey,
      ifeSystems,
      cspOutput,
      cspOutputError,
      cspMenuError,
      cspMenu,
      currentIfe,
      outputSuggestions,
      menuList,
      setError,
      isActive,
      isNewGenre,
      searchMenus,
      searchOutputItems,
    }
  },
  watch: {
    'v$.$invalid': function (val) {
      val && this.isExportCspVisible ? this.setError(new Error('InvalidForm'), 'ProjectExport') : this.setError(null, 'ProjectExport')
    }
  },
}
</script>

<style>
  #project-hide-nav-tab .p-tabview-nav-content {
    display: none;
  }

  #showTabPanel .p-tabview-nav-content {
    display: block !important;
  }
</style>

<style scoped>
  .p-button-danger {
    color: #dc3545 !important;
  }
</style>
